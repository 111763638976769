<template>
    <section>
      <div class="w-full bg-white rounded-md mt-2 p-4">
        <div class="w-full flex gap-2 items-center">
          <i class="pi pi-list"></i>
          <p class="text-lg font-bold">Listado de Artículos</p>
          <Button @click="$router.push({ name: 'pharmasan.ventas.configuracion.articulos.crear' })" class="ml-auto rounded-md" label="Crear Artículo" />
        </div>
        <div class="grid grid-cold-1 md:grid-cols-2 lg:grid-cols-5 gap-2 my-2">
          <!-- filtro por codigos de articulo -->
          <div class="w-full">
            <label class="text-xs text-gray-600" for="cod_art">Código del artículo</label>
            <InputText class="w-full" id="cod_art" @keyup.enter="listarArticulos" v-model="filtros.ItemCode" />
          </div>
          <!-- filtro por descripcion -->
          <div class="w-full">
            <label class="text-xs text-gray-600" for="descripcion">Descripción</label>
            <InputText class="w-full" id="descripcion" @keyup.enter="listarArticulos" v-model="filtros.ItemName"/>
          </div>
          <!-- <div class="w-full">
            <label class="text-xs text-gray-600" for="cliente">Cliente</label>
            <Dropdown
              id="cliente"
              panelClass="text-xs"
              class="w-full rounded-md border-gray-300"
              v-model="filtros.CardCode"
              :options="listadoClientes"
              optionLabel="CardName"
              optionValue="CardCode"
              :filter="true"
              :showClear="true">
            </Dropdown>
          </div> -->
          <div class="w-full">
            <label class="text-xs text-gray-600" for="U_PHR_FecExpedi">Fecha inicio expedición</label>
            <input class="p-2 border rounded-md w-full" type="date" name="" id="U_PHR_FecExpedi" v-model="filtros.U_PHR_FecExpedi">
          </div>
          <div class="w-full">
            <label class="text-xs text-gray-600" for="U_PHR_FecVenci">Fecha fin vencimiento</label>
            <input class="p-2 border rounded-md w-full" type="date" name="" id="U_PHR_FecVenci" v-model="filtros.U_PHR_FecVenci">
          </div>
          <!-- filtro por bodega -->
          <!-- <div class="w-full">
            <label class="text-xs text-gray-600" for="bodega">Bodega</label>
            <AutoComplete
              panelClass="text-xs"
              id="bodega"
              class="w-full text-xs max-h-full"
              inputStyle="line-height: 1.3rem"
              inputClass="w-full text-xs"
              v-model="filtros.U_PHR_Bodega"
              :suggestions="bodegasFiltradas"
              @complete="buscarBodega($event)"
              field="name"
            />
          </div> -->
          <div class="flex gap-2">
            <Button @click="listarArticulos" class="mt-5 mb-auto rounded-md" label="Buscar" />
            <Button @click="limpiarFiltros" class="mt-5 mb-auto rounded-md p-button-outlined p-button-secondary" label="Limpiar" />
          </div>
        </div>
        <div class="w-full my-2">
          <DataTable class="p-datatable-sm text-xs" :value="articulos.rows" responsiveLayout="scroll">
            <Column header="Codigo">
              <template #body="{data}">
                <div class="text-center pt-3">
                  <p>{{ data.ItemCode }}</p>
                  <div class="flex gap-x-4 hover:text-blue-600 text-white transition ease-in-out duration-500">
                    <button class="underline italic" @click='EditMD(data.ItemCode)'>Editar</button>
                  </div>
                </div>
              </template>
            </Column>
            <Column field="ItemName" header="Descripción medicamento"></Column>
            <Column field="U_PHR_UndMedida" header="Und Medida"></Column>
            <Column field="U_PHR_FecExpedi" header="Fecha Expedición">
              <template #body="{data}">
                {{ dayjs(data.U_PHR_FecExpedi).format('YYYY-MM-DD HH:mm') }}
              </template>
            </Column>
            <Column field="U_PHR_FecVenci" header="Fecha Vencimiento">
              <template #body="{data}">
                  {{ dayjs(data.U_PHR_FecVenci).format('YYYY-MM-DD HH:mm') }}
              </template>
            </Column>
            <Column field="SWW" header="CUM"></Column>
            <Column field="CodeBars" header="CodeBars"></Column>
            <Column field="U_PHR_RegSanitario" header="Reg Sanitario"></Column>

            <Column header="Acción">
              <template #body="{data}">
                <div class="p-2 rounded-md text-center" >
                  <Button
                      @click='DeleteMD(data.ItemCode)'
                      severity="danger"
                      v-tooltip="'Eliminar'"
                      class='p-button-rounded p-button-icon-only mx-2'
                  >
                    <i class="pi pi-trash text-white"></i>
                  </Button>
                </div>
              </template>
            </Column>
          </DataTable>
          <Paginator v-model:first="offset"
            :rows="limit"
            :totalRecords="parseInt(articulos.count)"
            :rowsPerPageOptions="[2,10,20,30,100]"
            @page="onPage($event)"
          />
        </div>
      </div>
      <Toast />
    </section>
</template>
<script setup>
  import dayjs from 'dayjs'
  import { useRouter } from 'vue-router'
  import { useToast } from 'primevue/usetoast'
  import Swal from 'sweetalert2'
  import ArticulosService from '../../../../../services/medicamentos.service'
  import { onMounted, ref, computed } from 'vue'
    // name: 'ListadoOrdenesVenta'
    // Servicios
    const router = useRouter()
    const toast = useToast()
    const _ArticulosService = ref(new ArticulosService())
    // Referencias
    const articulos = ref([])
    const ArtSeleccionado = ref()
    const limit = ref(10)
    const offset = ref(0)
    const filtros = ref({
      ItemName: null,
      CardCode: null,
      U_PHR_FecExpedi: null,
      U_PHR_FecVenci: null
    })
    const params = computed(() => {
      return {
        limit: limit.value,
        offset: offset.value,
        ...filtros.value
      }
    })
    // Metodos
    const onPage = ({ first, page, pageCount, rows }) => {
      limit.value = rows
      listarArticulos(params.value)
    }
    const listarArticulos = () => {
      const object = {
        ...params.value,
        limit: limit.value,
        offset: offset.value
      }
      _ArticulosService.value.paginate(object).then(({ data }) => {
        articulos.value = data
      })
    }

    const EditMD = (id) => {
      router.push({ name: 'pharmasan.ventas.configuracion.articulos.editar', params: { id } })
    }
    const DeleteMD = (id) => {
      Swal.fire({
          title: 'Esta seguro de eliminar este medicamento?',
          showDenyButton: true,
          denyButtonText: 'Cancelar',
          confirmButtonText: 'Eliminar'
        }).then((result) => {
          if (result.isConfirmed) {
            _ArticulosService.value.deleteArticulo(id).then(({ data }) => {
              if (data.error) {
                console.error('Error al eliminar el medicamento, por favor intente nuevamente')
              } else {
                toast.add({ severity: 'success', summary: 'Exito', detail: 'Medicamento eliminado exitosamente', life: 3000 })
                listarArticulos()
              }
            })
          } else if (result.isDenied) {
            Swal.fire('Medicamentos no eliminado', '', 'info')
          }
        })
    }
    const limpiarFiltros = () => {
      filtros.value = {
        CardCode: null,
        U_PHR_FecExpedi: null,
        U_PHR_FecVenci: null,
        Id: null,
        U_PHR_Bodega: null,
        U_ACS_TpoIdentf: null,
        U_PHR_NumDcto: null,
        U_PHR_ModContrato: null
      }
      ArtSeleccionado.value = ''
      listarArticulos()
    }
    onMounted(() => {
      listarArticulos()
    })
</script>
<style>
  .p-dropdown-label{
    font-size: 0.75rem !important
  }
  .tipoId .p-dropdown-trigger {
    display: none !important;
  }
  ::-webkit-scrollbar-thumb {
    background: rgb(189, 189, 189);
    border-radius: 9999px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  ::-webkit-input-placeholder { /* Edge */
    color: gray;
  }
  ::-webkit-scrollbar {
    width: 0.4rem;
  }
</style>
